html {
  font-size: 0.875rem !important;
}

@media only screen and (min-width: 1441px) {
  html {
    font-size: 1rem !important;
  }
}

@media only screen and (min-width: 2000px) {
  html {
    font-size: 1.125rem !important;
  }
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}
a {
  color: #1976d2;
}
p {
  margin: 0;
}

.rdw-editor-toolbar {
  padding: .65rem .3rem .3rem;
  border: 0;
  margin-bottom: 0;
  border: 1px solid rgba(0,0,0,.2);
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rdw-option-wrapper {
  box-shadow: none;
  border: 1px solid transparent;
  border-radius: 3px;
}

.rdw-option-wrapper:hover {
  box-shadow: none;
  border: 1px solid transparent;
  background: #f8f8f8;
}

.rdw-option-active, .rdw-option-active:hover {
  box-shadow: none;
  border: 1px solid transparent;
  background: #E8F0FE;
}

a.rdw-dropdown-selectedtext {
  color: #333;
  padding-left: 10px;
}

.rdw-editor-main {
  font-family: 'Roboto';
  font-size: 1.25rem;
  font-weight: 300;
}

.rdw-dropdown-wrapper, .rdw-block-dropdown {
  box-shadow: none;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,.2);
}
.rwd-dropdown-wrapper:hover, .rdw-block-dropdown:hover {
  box-shadow: none !important;
  border-radius: 3px;
  border: 1px solid #000 !important;
}
.rdw-dropdown-wrapper:active, .rdw-block-dropdown:active {
  box-shadow: none !important;
  border-radius: 3px;
  border: 1px solid #000 !important;
}

.rdw-dropdownoption-default:hover {
  background: #f4f4f4;
}
.rdw-dropdownoption-active, .rdw-dropdownoption-active:hover {
  background: #888;
  color: #fff;
}
.rdw-dropdown-optionwrapper {
  overflow-y: auto;
  box-shadow: none;
  border: 0;
  box-shadow: 0 0 8px rgba(0,0,0,.3) !important;
}
.rdw-dropdown-optionwrapper:hover {
  box-shadow: 0 0 8px rgba(0,0,0,.3) !important;
}
.rdw-dropdown-selectedtext .rdw-dropdown-carettoopen, .rdw-dropdown-selectedtext .rdw-dropdown-carettoclose {
  margin-top: 2px;
}
.rdw-dropdown-selectedtext span {
  margin-top: 1px;
}


.loader,
.loader:before,
.loader:after {
  background: #888;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #888;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}